import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction as MuiBottomNavigationAction } from "@mui/material";
import { styled } from "@mui/material/styles";

import "./bottomMenuBar.css";
import { Dashboard as DashboardIcon } from "@material-ui/icons";

import { QueryStats, Error } from "@mui/icons-material";

const COR_PRINCIPAL = "#4db6ac";

const BottomNavigationAction = styled(MuiBottomNavigationAction)({
	color: COR_PRINCIPAL,
	"&.Mui-selected": {
		color: "primary",
	},
});

class BottomMenuBar extends Component {
	state = { selecionado: String(window.location.hash).replace('#','') };
	handleNavigation = (path) => {
		this.props.history.push(path);
	};
	render() {
		return (
			<BottomNavigation
				showLabels={true}
				style={{
					display: "flex",
					alignItems: "start",
					justifyContent: window.innerWidth <= 600 ? "start" : "center",
					position: "fixed",
					paddingTop: 7,
					flexWrap: "no-wrap",
					right: 0,
					bottom: 0,
					left: 0,
					clear: "both",
					flexShrink: 0,
					minHeight: window.innerWidth <= 940 ? 90 : 70,
					width: "100%",
					overflowX: "scroll",
					boxShadow: "#000 2px 2px 5px",
					zIndex: 9999,
				}}
				value={this.state.selecionado}
				className="MuiPaper-elevation4  bottom-menu-sem-scrollbar"
				onChange={(event, selecionado) => {
					this.setState({ selecionado }); //or change current view
				}}
			>
				<BottomNavigationAction
					label="Dashboard"
					value="/"
					icon={<DashboardIcon />}
					style={{
						// color: COR_PRINCIPAL,
						width: 70,
						flexWrap: "wrap",
						textAlign: "center",
						marginInline: 5,
					}}
					onClick={() => this.handleNavigation("/")}
				/>

				<BottomNavigationAction
					label="Alertas"
					value="/alerta"
					icon={<Error />}
					style={{
						// color: COR_PRINCIPAL,
						width: 70,
						flexWrap: "wrap",
						textAlign: "center",
						marginInline: 5,
					}}
					onClick={() => this.handleNavigation("/alerta")}
				/>

				<BottomNavigationAction
					label="Historico"
					value="/historico"
					icon={<QueryStats />}
					style={{
						// color: COR_PRINCIPAL,
						width: 70,
						flexWrap: "wrap",
						textAlign: "center",
						marginInline: 5,
					}}
					onClick={() => this.handleNavigation("/historico")}
				/>
			</BottomNavigation>
		);
	}
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BottomMenuBar));
