import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CssBaseline, Grid as GridMUI, FormLabel, Card } from "@material-ui/core";

import CardPrevisaoTempo from "../common/components/cardPrevisaoTempo";

import {
  setDataGrafico,
  setPeriodoGrafico,
  getLista,
  inativar,
} from "./historicoActions";
import { getLista as getListaDispositivo } from "../dispositivo/dispositivoActions";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import locale from "@amcharts/amcharts4/lang/pt_BR.js";
import DateFormat from "../common/dateFormat/DateFormat";
import Button from "../common/button/button";
import Table from "../common/table/table";
import THead from "../common/table/tHead";
import Tr from "../common/table/tr";
import NewTr from "../common/table/newTr";
import Th from "../common/table/th";
import TBody from "../common/table/tBody";
import Td from "../common/table/td";
import ContentCardBody from "../common/template/contentCardBody";
import BoxLoader from "../common/boxLoader/boxLoader";

class Historico extends Component {
  state = {
    dispositivos: null,
    modoTabela: false,
    //coisas do grafico
    mobile: window.innerWidth < 500,
    dia: null,
    semana: null,
    mes: null,
    ano: null,
  };
  componentDidMount() {
    this.props.getListaDispositivo(this.props.sessao.id || 0);
    if (this.props.dispositivoSelecionado) {
      this.props.getLista();
    }
    this.setState({ ...this.state, [this.props.periodoGrafico]: true });
    this.montarGraficoLine(this.props.lista);
  }

  //se alterar o dispositivo ou o componente
  componentDidUpdate(prevProps, prevState) {
    // Quando o dispositivo selecionado ou a data do gráfico forem
    // alterados recarrega a lista com base na nova data ou no novo dispositivo
    const dataGraficoAlterada =
      prevProps.dataGrafico !== this.props.dataGrafico;

    const dispositivoAlterado =
      JSON.stringify(prevProps.listaDispositivos) !==
        JSON.stringify(this.props.listaDispositivos) ||
      JSON.stringify(prevProps.dispositivoSelecionado) !==
        JSON.stringify(this.props.dispositivoSelecionado);

    const listaAtualizada =
      JSON.stringify(prevProps.lista) !== JSON.stringify(this.props.lista);

    const mudouModoTabela = prevState.modoTabela !== this.state.modoTabela;

    if (dispositivoAlterado || dataGraficoAlterada) {
      this.props.getLista();
    }
    if (listaAtualizada || mudouModoTabela) {
      this.render();
      this.montarGraficoLine(this.props.lista);
    }
  }

  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <GridMUI
          container
          spacing={1}
          style={{
            alignItems: "center",
            flexDirection: "column",
            paddingTop: 30,
          }}
        >
          <CardPrevisaoTempo
            xs={12}
            sm={12}
            md={12}
            lg={12}
            width="94.8%"
            previsao={this.props.previsaoTempo}
          />
          <GridMUI
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ minWidth: "90%", marginTop: 20, marginBottom: 150 }}
          >
            <Card
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                boxShadow: "#aaa 0px 1px 5px 2px",
                paddingBottom: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "no-wrap",
                  // minWidth: "1150px",
                  width: "100%",
                  paddingTop: 15,
                  marginBottom: -1,
                  boxShadow: "inset 0 0 2px #ddd",
                  backgroundColor: "##fff",
                }}
              >
                <Grid
                  cols="3 3 3 3"
                  style={{
                    height: 40,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor:
                      this.props.periodoGrafico === "dia" ? "#fff" : "#f5f5f5",
                    cursor: "pointer",
                    boxShadow:
                      this.props.periodoGrafico === "dia"
                        ? "inset 0 2px 2px 1px #e7e7e7"
                        : "#e9e4e4 0px 2px 5px 1px inset",
                    marginTop: this.props.periodoGrafico === "dia" ? -4 : null,
                    borderRadius: "15px 15px 0 0",
                    borderBottom: `1px solid ${
                      this.props.periodoGrafico === "dia" ? "#fff" : "#ddd"
                    } `,
                  }}
                  title="Ver o gráfico do dia"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      dia: true,
                      semana: null,
                      mes: null,
                      ano: null,
                    });
                    this.props.setPeriodoGrafico("dia");
                  }}
                >
                  <span
                    style={{
                      fontSize: "0.9rem",
                    }}
                  >
                    Dia
                  </span>
                </Grid>
                <Grid
                  cols="3 3 3 3"
                  style={{
                    height: 40,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor:
                      this.props.periodoGrafico === "semana"
                        ? "#fff"
                        : "#f5f5f5",
                    cursor: "pointer",
                    boxShadow:
                      this.props.periodoGrafico === "semana"
                        ? "inset 0 2px 2px 1px #e7e7e7"
                        : "#e9e4e4 0px 2px 5px 1px inset",
                    marginTop:
                      this.props.periodoGrafico === "semana" ? -4 : null,
                    borderRadius: "15px 15px 0 0",
                    borderBottom: `1px solid ${
                      this.props.periodoGrafico === "semana" ? "#fff" : "#ddd"
                    } `,
                  }}
                  title="Ver o gráfico do semana"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      dia: null,
                      semana: true,
                      mes: null,
                      ano: null,
                    });
                    this.props.setPeriodoGrafico("semana");
                  }}
                >
                  <span
                    style={{
                      fontSize: "0.9rem",
                    }}
                  >
                    Semana
                  </span>
                </Grid>
                <Grid
                  cols="3 3 3 3"
                  style={{
                    height: 40,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor:
                      this.props.periodoGrafico === "mes" ? "#fff" : "#f5f5f5",
                    cursor: "pointer",
                    boxShadow:
                      this.props.periodoGrafico === "mes"
                        ? "inset 0 2px 2px 1px #e7e7e7"
                        : "#e9e4e4 0px 2px 5px 1px inset",
                    marginTop: this.props.periodoGrafico === "mes" ? -4 : null,
                    borderRadius: "15px 15px 0 0",
                    borderBottom: `1px solid ${
                      this.props.periodoGrafico === "mes" ? "#fff" : "#ddd"
                    } `,
                  }}
                  title="Ver o gráfico do mês"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      dia: null,
                      semana: null,
                      mes: true,
                      ano: null,
                    });
                    this.props.setPeriodoGrafico("mes");
                  }}
                >
                  <span
                    style={{
                      fontSize: "0.9rem",
                    }}
                  >
                    Mês
                  </span>
                </Grid>

                <Grid
                  cols="3 3 3 3"
                  style={{
                    height: 40,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor:
                      this.props.periodoGrafico === "ano" ? "#fff" : "#f5f5f5",
                    cursor: "pointer",
                    boxShadow:
                      this.props.periodoGrafico === "ano"
                        ? "inset 0 2px 2px 1px #e7e7e7"
                        : "#e9e4e4 0px 2px 5px 1px inset",
                    marginTop: this.props.periodoGrafico === "ano" ? -4 : null,
                    borderRadius: "15px 15px 0 0",
                    borderBottom: `1px solid ${
                      this.props.periodoGrafico === "ano" ? "#fff" : "#ddd"
                    } `,
                  }}
                  title="Ver o gráfico do ano"
                  onClick={() => {
                    this.setState({
                      ...this.state,
                      dia: null,
                      semana: null,
                      mes: null,
                      ano: true,
                    });
                    this.props.setPeriodoGrafico("ano");
                  }}
                >
                  <span
                    style={{
                      fontSize: "0.9rem",
                    }}
                  >
                    Ano
                  </span>
                </Grid>
              </div>
              <Row
                style={{
                  display: "flex",
                  width: "100%",
                  backgroundColor: "#fff",
                  borderTop: "1px solid #ddd",
                  paddingTop: 30,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid cols="0 0 2 2"></Grid>
                <Grid
                  cols="12 12 8 8"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <button
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "1.5rem",
                      fontWeight: 500,
                      boxShadow: this.desabilitarBotaoVoltarData()
                        ? "inset 0 0 16px 1px #ccc"
                        : "0 0 10px 1px #ccc",
                      color: "#0070cc",
                      width: 30,
                      height: 30,
                      borderRadius: "100%",
                      backgroundColor: "#fff",
                      border: "none",
                      margin: 20,
                    }}
                    onFocus={(e) => {
                      e.target.style.outline = "none";
                    }}
                    onClick={() => {
                      const {
                        dataGrafico,
                        dataInicialGrafico,
                        dataFinalGrafico,
                        periodoGrafico,
                      } = this.props;
                      const tempo = periodoGrafico === "semana" ? -7 : -1;
                      this.voltarAvancarDataGrafico({
                        dataGrafico,
                        dataInicialGrafico,
                        dataFinalGrafico,
                        tempo,
                        periodoGrafico,
                      });
                    }}
                    disabled={this.desabilitarBotaoVoltarData()}
                  >
                    {"<"}
                  </button>
                  {
                    this.state.dia && (
                      <input
                        style={{
                          fontSize: "1.2rem",
                          padding: 7,
                          border: "1px solid #999",
                          borderRadius: 10,
                        }}
                        required
                        type="date"
                        value={this.props.dataGrafico}
                        max={DateFormat.getDataAtual().replace(/\//g, "-")}
                        onFocus={(e) => (e.target.style.outline = "none")}
                        onChange={(e) => {
                          const valorData =
                            e.target.value ||
                            DateFormat.getDataAtual().replace(/\//g, "-");

                          this.props.setDataGrafico({ dataGrafico: valorData });
                        }}
                      ></input>
                    )
                  }
                  {this.state.semana && (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        fontSize: "1.2rem",
                      }}
                    >
                      {`De ${DateFormat.formatarDataSqlParaTela(
                        this.props.dataInicialGrafico
                      )}
                       até 
                      ${DateFormat.formatarDataSqlParaTela(
                        this.props.dataFinalGrafico
                      )}
                  `}
                    </span>
                  )}
                  {
                    this.state.mes && (
                      <select
                        style={{
                          fontSize: "1.2rem",
                          padding: 11,
                          border: "1px solid #999",
                          backgroundColor: "#fff",
                          borderRadius: 10,
                        }}
                        value={this.props.dataGrafico.substring(5, 7)}
                        onFocus={(e) => (e.target.style.outline = "none")}
                        onChange={(e) => {
                          const dataAtual = this.props.dataGrafico;
                          const novaData =
                            dataAtual.substring(0, 5) + e.target.value + "-01";

                          const dataMaiorQueAtual =
                            new Date(novaData).getTime() > new Date().getTime();
                          if (!dataMaiorQueAtual) {
                            this.props.setDataGrafico({
                              dataGrafico: novaData,
                            });
                          }
                        }}
                      >
                        <option value="01">Janeiro</option>
                        <option value="02">Fevereiro</option>
                        <option value="03">Março</option>
                        <option value="04">Abril</option>
                        <option value="05">Maio</option>
                        <option value="06">Junho</option>
                        <option value="07">Julho</option>
                        <option value="08">Agosto</option>
                        <option value="09">Setembro</option>
                        <option value="10">Outubro</option>
                        <option value="11">Novembro</option>
                        <option value="12">Dezembro</option>
                      </select>
                    )
                  }
                  {
                    (this.state.mes || this.state.ano) && (
                      <select
                        style={{
                          marginLeft: this.state.mes ? 5 : 0,
                          fontSize: "1.2rem",
                          padding: 11,
                          border: "1px solid #999",
                          backgroundColor: "#fff",
                          borderRadius: 10,
                        }}
                        value={this.props.dataGrafico.substring(0, 4)}
                        onFocus={(e) => (e.target.style.outline = "none")}
                        onChange={(e) => {
                          const novaData = e.target.value + "-01-01";
                          this.props.setDataGrafico({ dataGrafico: novaData });
                        }}
                      >
                        {(() => {
                          let options = [];
                          let anoInicioLeituras = 2023;
                          for (
                            let i = anoInicioLeituras;
                            i <= new Date().getFullYear();
                            i++
                          ) {
                            options.push(
                              <option key={i} value={String(i)}>
                                {i}
                              </option>
                            );
                          }
                          return options;
                        })()}
                      </select>
                    )
                  }
                  <button
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "1.5rem",
                      fontWeight: 500,
                      boxShadow: this.desabilitarBotaoAvancarData()
                        ? "inset 0 0 16px 1px #ccc"
                        : "0 0 10px 1px #ccc",
                      color: "#0070cc",
                      width: 30,
                      height: 30,
                      borderRadius: "100%",
                      backgroundColor: "#fff",
                      border: "none",
                      margin: 20,
                    }}
                    onFocus={(e) => {
                      e.target.style.outline = "none";
                    }}
                    onClick={() => {
                      const {
                        dataGrafico,
                        dataInicialGrafico,
                        dataFinalGrafico,
                        periodoGrafico,
                      } = this.props;
                      const tempo = periodoGrafico === "semana" ? 7 : 1;
                      this.voltarAvancarDataGrafico({
                        dataGrafico,
                        dataInicialGrafico,
                        dataFinalGrafico,
                        tempo,
                        periodoGrafico,
                      });
                    }}
                    disabled={this.desabilitarBotaoAvancarData()}
                  >
                    {">"}
                  </button>
                </Grid>
                <Grid
                  cols="12 12 2 2"
                  style={{ display: "flex", justifyContent: "end" }}
                >
                  <Button
                    title={
                      this.state.modoTabela
                        ? "alternar para o modo gráfico"
                        : "alternar para o modo tabela"
                    }
                    style={{
                      width: 40,
                      height: 40,
                      padding: 0,
                    }}
                    text={
                      <i
                        style={{
                          fontSize: "1.1rem",
                          verticalAlign: "middle",
                        }}
                        className={
                          this.state.modoTabela
                            ? "fas fa-chart-line"
                            : "fas fa-th-list"
                        }
                      />
                    }
                    type={"info"}
                    event={() => {
                      this.setState({
                        ...this.state,
                        modoTabela: !this.state.modoTabela,
                      });
                    }}
                  />
                </Grid>
              </Row>
              {this.state.modoTabela ? (
                <ContentCardBody>
                  <Table responsive>
                    <THead>
                      <Tr>
                        <Th sticky minWidth={80} alignCenter>
                          Data/Hora
                        </Th>
                        <Th sticky minWidth={80} alignCenter>
                          {window.innerWidth < 400
                            ? "Temp.(°C)"
                            : "Temperatura(°C)"}
                        </Th>
                        <Th sticky minWidth={80} alignCenter>
                          {window.innerWidth < 400
                            ? "Prec.(mm)"
                            : "Precipitação(mm)"}
                        </Th>
                        <Th sticky minWidth={80} alignCenter>
                          {window.innerWidth < 400
                            ? "Vel. Vento(km/h)"
                            : "Velocidade do vento(km/h)"}
                        </Th>
                        <Th sticky minWidth={80} alignCenter>
                          {window.innerWidth < 400
                            ? "Umid. Ar(%)"
                            : "Umidade do Ar(%)"}
                        </Th>
                        <Th sticky minWidth={80} alignCenter>
                          {window.innerWidth < 400
                            ? "Umid. Solo(%)"
                            : "Umidade do Solo(%)"}
                        </Th>
                      </Tr>
                    </THead>
                    {this.props.aguardando ? (
                      <BoxLoader />
                    ) : (
                      <TBody>
                        {this.props.lista
                          .sort(
                            (a, b) =>
                              new Date(b.data_hora).getTime() -
                              new Date(a.data_hora).getTime()
                          )
                          .map((item) => (
                            <NewTr
                              key={item.id}
                              celulas={
                                <>
                                  <Td alignCenter>
                                    {new Date(item.data_hora).toLocaleString(
                                      "pt-BR",
                                      {
                                        dateStyle: "short",
                                        timeStyle: "medium",
                                      }
                                    )}
                                  </Td>
                                  <Td alignCenter>
                                    {isNaN(parseFloat(item.temperatura))
                                      ? "-"
                                      : item.temperatura + " °C"}
                                  </Td>
                                  <Td alignCenter>
                                    {isNaN(parseFloat(item.precipitacao))
                                      ? "-"
                                      : String(item.precipitacao).replace('.',',') + " mm"}
                                  </Td>
                                  <Td alignCenter>
                                    {isNaN(parseFloat(item.velocidade_vento))
                                      ? "-"
                                      : item.velocidade_vento + " km/h"}
                                  </Td>
                                  <Td alignCenter>
                                    {isNaN(parseFloat(item.umidade_ar))
                                      ? "-"
                                      : item.umidade_ar + " %"}
                                  </Td>
                                  <Td alignCenter>
                                    {isNaN(parseFloat(item.umidade_solo))
                                      ? "-"
                                      : item.umidade_solo + " %"}
                                  </Td>
                                </>
                              }
                              botoes={[
                                {
                                  type: "danger",
                                  icon: "fas fa-trash-alt",
                                  disabled: false,
                                  event: () => {
                                    this.props.inativar(item.id);
                                  },
                                },
                              ]}
                            />
                          ))}
                      </TBody>
                    )}
                  </Table>
                </ContentCardBody>
              ) : (
                <div
                  id={`grafico`}
                  style={{
                    width: "100%",
                    // minWidth: "1150px",
                    paddingTop: 15,
                    backgroundColor: "#fff",
                    height: "500px",
                  }}
                ></div>
              )}
            </Card>
          </GridMUI>
        </GridMUI>
      </React.Fragment>
    );
  }

  //deixa botoes da barra de rolagem do gráfico quadrados
  customizeChartGrip(grip) {
    // Remove default grip image
    grip.icon.disabled = true;

    // Disable background
    grip.background.disabled = true;

    // Add rotated rectangle as bi-di arrow
    const img = grip.createChild(am4core.Rectangle);
    img.width = 15;
    img.height = 15;
    img.fill = am4core.color("#0070cc");
    img.stroke = am4core.color("#0070cc");
    img.rotation = 45;
    img.align = "center";
    img.valign = "middle";

    // Add vertical bar
    const line = grip.createChild(am4core.Rectangle);
    line.height = 60;
    line.width = 3;
    line.fill = am4core.color("#0070cc");
    line.stroke = am4core.color("#0070cc");
    line.align = "center";
    line.valign = "middle";
  }

  montarGraficoLine(lista) {
    am4core.useTheme(am4themes_animated);

    let chart = am4core.create("grafico", am4charts.XYChart);
    // const subtitle = chart.titles.create();
    // subtitle.text = this.props.registro.nome_unidade_consumidora;
    // subtitle.fontWeight = "bold";
    // subtitle.fontSize = window.innerWidth < 1150 ? "0.7rem" : "1rem";
    // subtitle.fill = am4core.color("#000");
    // subtitle.marginBottom = 30;

    // const title = chart.titles.create();
    // title.text = "Histórico";
    // title.fontWeight = "bold";
    // title.fontSize = window.innerWidth < 1150 ? "0.8rem" : "1.2rem";
    // title.fill = am4core.color("#000");
    // title.marginBottom = 30;

    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.filePrefix = "Gráfico";
    chart.exporting.menu.align = "right";
    chart.exporting.menu.verticalAlign = "top";
    chart.exporting.menu.items = [
      {
        label: "...",
        menu: [
          {
            type: "png",
            label: "PNG",
          },
        ],
      },
    ];
    chart.language.locale = locale;
    chart.language.locale["_decimalSeparator"] = ",";
    chart.language.locale["_thousandSeparator"] = ".";
    chart.numberFormatter.numberFormat = "###,###,###,###.00";
    chart.dateFormatter.dateFormat = formatoDataHora;
    chart.leftAxesContainer.layout = "vertical";
    let formatoDataHora = "HH:mm";
    if (["semana", "mes", "ano"].includes(this.props.periodoGrafico)) {
      formatoDataHora = "dd/MM/yyyy HH:mm";
    }

    // Create axis
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.groupCount = 10;
    // dateAxis.markUnitChange = false;
    dateAxis.startLocation = -1;
    dateAxis.endLocation = 2;
    dateAxis.dateFormats.setKey("minute", formatoDataHora);
    dateAxis.dateFormats.setKey("hour", formatoDataHora);
    dateAxis.dateFormats.setKey("day", "dd/MM/yyyy");
    dateAxis.dateFormats.setKey("month", "MMMM");
    dateAxis.periodChangeDateFormats.setKey("minute", formatoDataHora);
    dateAxis.periodChangeDateFormats.setKey("hour", formatoDataHora);
    dateAxis.periodChangeDateFormats.setKey("day", "dd/MM/yyyy");
    dateAxis.periodChangeDateFormats.setKey("month", "MMMM");
    // dateAxis.periodChangeDateFormats.setKey("minute", "hh:mm");
    dateAxis.baseInterval = {
      timeUnit: "minute",
      count: 5,
    };
    dateAxis.gridIntervals.setAll([
      { timeUnit: "minute", count: 1 },
      { timeUnit: "minute", count: 5 },
      { timeUnit: "minute", count: 10 },
      { timeUnit: "minute", count: 15 },
      { timeUnit: "minute", count: 30 },
      { timeUnit: "hour", count: 1 },
      // { timeUnit: "hour", count: 3 },
      { timeUnit: "hour", count: 6 },
      // { timeUnit: "hour", count: 12 },
      { timeUnit: "day", count: 1 },
      { timeUnit: "month", count: 1 },
    ]);

    // dateAxis.renderer.grid.template.location = 0.0001;
    dateAxis.renderer.labels.template.fill = am4core.color("#000"); //cor do texto eixo x

    //padding horizontal dos label do eixo
    dateAxis.renderer.minLabelPosition = 0.05;
    dateAxis.renderer.maxLabelPosition = 0.95;
    dateAxis.renderer.minGridDistance = 120;

    // Create series Temperatura
    const valueAxisTemperatura = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxisTemperatura.renderer.labels.template.fill = am4core.color("#000"); //cor do texto eixo y
    const valueAxisTooltipTemperatura = valueAxisTemperatura.tooltip;
    valueAxisTooltipTemperatura.background.fill = am4core.color("#0070cc");
    valueAxisTooltipTemperatura.stroke = am4core.color("#fff");
    valueAxisTooltipTemperatura.background.strokeWidth = 0;
    valueAxisTooltipTemperatura.background.cornerRadius = 3;
    valueAxisTooltipTemperatura.background.pointerLength = 0;
    valueAxisTooltipTemperatura.dy = 5;

    valueAxisTemperatura.renderer.line.strokeOpacity = 1;
    valueAxisTemperatura.renderer.line.strokeWidth = 2;
    valueAxisTemperatura.marginBottom = 20;
    valueAxisTemperatura.align = "right";

    const temperatura = chart.series.push(new am4charts.LineSeries());
    temperatura.dataFields.valueY = "temperatura";
    temperatura.dataFields.dateX = "data_hora";
    temperatura.yAxis = valueAxisTemperatura;
    temperatura.name = "Temperatura (°C)";
    temperatura.tooltipText = `Temperatura às {dateX.formatDate("${formatoDataHora}")}h: [bold]{valueY.formatNumber("###,###,###,###.0")} °C[/]`;
    temperatura.strokeWidth = 2;
    temperatura.tensionX = 1;
    temperatura.stacked = true;
    temperatura.stroke = am4core.color("#F00");
    temperatura.fill = am4core.color("#F00");
    temperatura.fillOpacity = 0.2;

    // Create series Precipitação
    const valueAxisPrecipitacao = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxisPrecipitacao.renderer.labels.template.fill = am4core.color("#000"); //cor do texto eixo y
    // valueAxisPrecipitacao.syncWithAxis = chart.yAxes.getIndex(0);
    const valueAxisTooltipPrecipitacao = valueAxisPrecipitacao.tooltip;
    valueAxisTooltipPrecipitacao.background.fill = am4core.color("#0070cc");
    valueAxisTooltipPrecipitacao.stroke = am4core.color("#fff");
    valueAxisTooltipPrecipitacao.background.strokeWidth = 0;
    valueAxisTooltipPrecipitacao.background.cornerRadius = 3;
    valueAxisTooltipPrecipitacao.background.pointerLength = 0;
    valueAxisTooltipPrecipitacao.dy = 5;

    valueAxisPrecipitacao.renderer.line.strokeOpacity = 1;
    valueAxisPrecipitacao.renderer.line.strokeWidth = 2;
    valueAxisPrecipitacao.marginBottom = 20;
    valueAxisPrecipitacao.align = "right";
    const precipitacao = chart.series.push(new am4charts.LineSeries());
    precipitacao.dataFields.valueY = "precipitacao";
    precipitacao.dataFields.dateX = "data_hora";
    precipitacao.yAxis = valueAxisPrecipitacao;
    precipitacao.name = "Precipitação (mm)";
    precipitacao.tooltipText = `Precipitação às {dateX.formatDate("${formatoDataHora}")}h: [bold]{valueY.formatNumber("###,###,###,###")} mm[/]`;
    precipitacao.strokeWidth = 2;
    precipitacao.tensionX = 1;
    precipitacao.stacked = true;
    precipitacao.stroke = am4core.color("#017acd");
    precipitacao.fill = am4core.color("#017acd");
    precipitacao.fillOpacity = 0.2;

    // Create series Velocidade do Vento

    const valueAxisVelocidadeVento = chart.yAxes.push(
      new am4charts.ValueAxis()
    );
    // valueAxisVelocidadeVento.syncWithAxis = chart.yAxes.getIndex(0);
    valueAxisVelocidadeVento.renderer.labels.template.fill =
      am4core.color("#000"); //cor do texto eixo y
    const valueAxisTooltipVelocidadeVento = valueAxisVelocidadeVento.tooltip;
    valueAxisTooltipVelocidadeVento.background.fill = am4core.color("#0070cc");
    valueAxisTooltipVelocidadeVento.stroke = am4core.color("#fff");
    valueAxisTooltipVelocidadeVento.background.strokeWidth = 0;
    valueAxisTooltipVelocidadeVento.background.cornerRadius = 3;
    valueAxisTooltipVelocidadeVento.background.pointerLength = 0;
    valueAxisTooltipVelocidadeVento.dy = 5;

    valueAxisVelocidadeVento.renderer.line.strokeOpacity = 1;
    valueAxisVelocidadeVento.renderer.line.strokeWidth = 2;
    valueAxisVelocidadeVento.marginBottom = 20;
    valueAxisVelocidadeVento.align = "right";

    const velocidade_vento = chart.series.push(new am4charts.LineSeries());
    velocidade_vento.dataFields.valueY = "velocidade_vento";
    velocidade_vento.dataFields.dateX = "data_hora";
    velocidade_vento.yAxis = valueAxisVelocidadeVento;
    velocidade_vento.name = "Velocidade do Vento (km/h)";
    velocidade_vento.tooltipText = `Velocidade do Vento às {dateX.formatDate("${formatoDataHora}")}h: [bold]{valueY.formatNumber("###,###,###,###.0")} km/h[/]`;
    velocidade_vento.strokeWidth = 2;
    velocidade_vento.tensionX = 1;
    velocidade_vento.stacked = true;
    velocidade_vento.stroke = am4core.color("#0F0");
    velocidade_vento.fill = am4core.color("#0F0");
    velocidade_vento.fillOpacity = 0.2;

    // Create series Velocidade do Vento

    const valueAxisUmidadeSolo = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxisVelocidadeVento.syncWithAxis = chart.yAxes.getIndex(0);
    valueAxisUmidadeSolo.renderer.labels.template.fill = am4core.color("#000"); //cor do texto eixo y
    const valueAxisTooltipUmidadeSolo = valueAxisUmidadeSolo.tooltip;
    valueAxisTooltipUmidadeSolo.background.fill = am4core.color("#0070cc");
    valueAxisTooltipUmidadeSolo.stroke = am4core.color("#fff");
    valueAxisTooltipUmidadeSolo.background.strokeWidth = 0;
    valueAxisTooltipUmidadeSolo.background.cornerRadius = 3;
    valueAxisTooltipUmidadeSolo.background.pointerLength = 0;
    valueAxisTooltipUmidadeSolo.dy = 5;

    valueAxisUmidadeSolo.renderer.line.strokeOpacity = 1;
    valueAxisUmidadeSolo.renderer.line.strokeWidth = 2;
    valueAxisUmidadeSolo.align = "right";

    const umidade_solo = chart.series.push(new am4charts.LineSeries());
    umidade_solo.dataFields.valueY = "umidade_solo";
    umidade_solo.dataFields.dateX = "data_hora";
    umidade_solo.yAxis = valueAxisUmidadeSolo;
    umidade_solo.name = "Umidade do Solo (%)";
    umidade_solo.tooltipText = `Umidade do solo às {dateX.formatDate("${formatoDataHora}")}h: [bold]{valueY.formatNumber("###,###,###,###.0")} %[/]`;
    umidade_solo.strokeWidth = 2;
    umidade_solo.tensionX = 1;
    umidade_solo.stacked = true;
    umidade_solo.stroke = am4core.color("#8f5524");
    umidade_solo.fill = am4core.color("#8f5524");
    umidade_solo.fillOpacity = 0.2;

    //Estilo da scrollbar
    chart.scrollbarX = new am4charts.XYChartScrollbar();

    chart.scrollbarX.series.push(precipitacao);
    chart.scrollbarX.series.push(velocidade_vento);
    chart.scrollbarX.series.push(temperatura);
    chart.scrollbarX.series.push(umidade_solo);
    chart.scrollbarX.unselectedOverlay.fill = am4core.color("#fff");
    chart.scrollbarX.unselectedOverlay.fillOpacity = 0.7;
    chart.scrollbarX.unselectedOverlay.stroke = am4core.color("#fff");

    chart.scrollbarX.background.fill = am4core.color("#fff");
    chart.scrollbarX.thumb.background.fill = am4core.color("#2190ff"); //cor do trecho selecionado
    chart.scrollbarX.thumb.background.fillOpacity = 0.3; //opacidade da cor do trecho selecionado
    chart.scrollbarX.stroke = am4core.color("#003040"); // cor do texto dentro do trecho seleionado
    chart.scrollbarX.strokeWidth = 0;

    //Cor da scrollbar ao passar o mouse
    chart.scrollbarX.startGrip.background.states.getKey(
      "hover"
    ).properties.fill = am4core.color("#2190ff");
    chart.scrollbarX.startGrip.background.states.getKey(
      "hover"
    ).properties.fillOpacity = 0.3;

    chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill =
      am4core.color("#2190ff");
    chart.scrollbarX.endGrip.background.states.getKey(
      "hover"
    ).properties.fillOpacity = 0.3;

    chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill =
      am4core.color("#2190ff");
    chart.scrollbarX.thumb.background.states.getKey(
      "hover"
    ).properties.fillOpacity = 0.3;

    //Adiciona legenda
    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.fill = am4core.color("#000");

    //Adiciona cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;
    chart.cursor.snapToSeries = [
      temperatura,
      precipitacao,
      velocidade_vento,
      umidade_solo,
    ];

    //configura a cor do tooltip x
    const dateAxisTooltip = dateAxis.tooltip;
    dateAxisTooltip.background.fill = am4core.color("#0070cc");
    dateAxisTooltip.stroke = am4core.color("#fff");
    dateAxisTooltip.background.strokeWidth = 0;
    dateAxisTooltip.background.cornerRadius = 3;
    dateAxisTooltip.background.pointerLength = 0;
    dateAxisTooltip.dy = 5;

    chart.data = lista.map((leitura) => {
      const {
        temperatura,
        precipitacao,
        velocidade_vento,
        umidade_solo,
        data_hora,
        ...rest
      } = leitura;
      return {
        temperatura,
        precipitacao,
        velocidade_vento,
        umidade_solo,
        data_hora: new Date(data_hora),
      };
    });

    //deixa botoes da barra de rolagem quadrados
    this.customizeChartGrip(chart.scrollbarX.startGrip);
    this.customizeChartGrip(chart.scrollbarX.endGrip);

    this.chart = chart;
  }

  voltarAvancarDataGrafico({
    dataGrafico,
    dataInicialGrafico,
    dataFinalGrafico,
    tempo,
    periodoGrafico,
  }) {
    const novaDataGrafico = new Date(dataGrafico);
    const novaDataInicialGrafico = new Date(dataInicialGrafico);
    const novaDataFinalGrafico = new Date(dataFinalGrafico);
    switch (periodoGrafico) {
      case "dia":
        novaDataGrafico.setDate(novaDataGrafico.getDate() + tempo);
        this.props.setDataGrafico({
          dataGrafico: novaDataGrafico.toISOString().substring(0, 10),
        });
        break;
      case "semana":
        novaDataInicialGrafico.setDate(
          novaDataInicialGrafico.getDate() + tempo
        );
        novaDataFinalGrafico.setDate(novaDataFinalGrafico.getDate() + tempo);
        this.props.setDataGrafico({
          dataInicialGrafico: novaDataInicialGrafico
            .toISOString()
            .substring(0, 10),
          dataFinalGrafico: novaDataFinalGrafico.toISOString().substring(0, 10),
        });
        break;
      case "mes":
        novaDataGrafico.setMonth(novaDataGrafico.getMonth() + tempo);
        this.props.setDataGrafico({
          dataGrafico: novaDataGrafico.toISOString().substring(0, 10),
        });
        break;
      case "ano":
        novaDataGrafico.setFullYear(novaDataGrafico.getFullYear() + tempo);
        this.props.setDataGrafico({
          dataGrafico: novaDataGrafico.toISOString().substring(0, 10),
        });
        break;
    }

    this.props.getLista();
  }

  desabilitarBotaoVoltarData() {
    if (
      this.props.periodoGrafico === "mes" &&
      this.props.dataGrafico.substring(0, 7) === "2023-01"
    )
      return true;
    if (
      this.props.periodoGrafico === "ano" &&
      this.props.dataGrafico.substring(0, 4) === "2023"
    )
      return true;
    return false;
  }
  desabilitarBotaoAvancarData() {
    if (
      this.props.periodoGrafico === "dia" &&
      this.props.dataGrafico === new Date().toISOString().substring(0, 10)
    )
      return true;
    if (
      this.props.periodoGrafico === "semana" &&
      this.props.dataFinalGrafico === this.props.dataLimiteSemanaAtual
    )
      return true;
    if (
      this.props.periodoGrafico === "mes" &&
      this.props.dataGrafico.substring(0, 7) ===
        new Date().toISOString().substring(0, 7)
    )
      return true;
    if (
      this.props.periodoGrafico === "ano" &&
      this.props.dataGrafico.substring(0, 4) ===
        new Date().toISOString().substring(0, 4)
    )
      return true;
    return false;
  }
}

const mapStateToProps = (state) => ({
  sessao: state.auth.usuario,
  periodoGrafico: state.historico.periodoGrafico,
  aguardando: state.historico.aguardando,
  previsaoTempo: state.historico.previsaoTempo,
  dataGrafico: state.historico.dataGrafico,
  dataInicialGrafico: state.historico.dataInicialGrafico, //usado apenas no modo semana
  dataFinalGrafico: state.historico.dataFinalGrafico, //usado apenas no modo semana
  dataLimiteSemanaAtual: state.historico.dataLimiteSemanaAtual, //usado apenas no modo semana
  lista: state.historico.lista,
  dispositivoSelecionado: state.dispositivo.dispositivoSelecionado,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setDataGrafico,
      setPeriodoGrafico,
      getLista,
      getListaDispositivo,
      inativar,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Historico);
