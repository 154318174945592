import axios from "axios";
import consts from "../consts";
import { setError } from "../common/toast/toast";

export function setModoTela(modo) {
	return async (dispatch, getState) => {
		dispatch({
			type: "DASHBOARD_MODO_TELA",
			payload: {
				modo: modo,
			},
		});
	};
}

export function getDados() {
	return async (dispatch, getState) => {
		try {
			dispatch({
				type: "RESETAR_DASHBOARD",
			});
			const dispositivoSelecionado = getState().dispositivo.dispositivoSelecionado || {};

			if (!dispositivoSelecionado.serie) {
				setError("Nenhum dispositivo selecionado");
				return {};
			}
			const url = `${consts.API_URL}/estacao/dashboard?serial=${dispositivoSelecionado.serie}`;

			const resp = await axios.get(url);
			dispatch({
				type: "DASHBOARD_LISTADO",
				payload: resp,
			});
		} catch (e) {
			setError("Controladora não respondeu");
		}
	};
}

export function getPrevisaoTempo() {
	return async (dispatch, getState) => {
		const dispositivoSelecionado = getState().dispositivo.dispositivoSelecionado || {};

		if (!dispositivoSelecionado.latitude || !dispositivoSelecionado.latitude) {
			setError("Não foi possível obter a localização do dispositivo");
			return {};
		}
		const url = `${consts.API_URL}/estacao/previsaoTempo?${dispositivoSelecionado.latitude ? "lat=" + dispositivoSelecionado.latitude : ""}${
			dispositivoSelecionado.longitude ? "&lon=" + dispositivoSelecionado.longitude : ""
		}`;

		try {
			const previsao = await axios.get(url);
			dispatch({
				type: "SET_PREVISAO_TEMPO_DASHBOARD",
				payload: previsao,
			});
		} catch (erro) {
			setError("Não foi possível obter a previsão do tempo");
		}
	};
}
