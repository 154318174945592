const INITIAL_STATE = {
  modoTela: "dashboard",
  registro: {
    tempo_ultima_mensagem: "",
    data_hora: "",
    precipitacao: "",
    clima_atual_temperatura: "",
    clima_atual_umidade_ar: "",
    umidade_solo: "",
    velocidade_vento: "",
    direcao_vento: "",
    temperatura_ar_minima: "",
    temperatura_ar_maxima: "",
    soma_precipitacao: "",
    umidade_ar_minima: "",
    umidade_ar_maxima: "",
    intermediario: "",
    rssi: "",
    estado_bateria: { percentualBateria: 0, alerta: "" },
    previsao: {},
    serial:''
  },
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case "DASHBOARD_MODO_TELA":
      return { ...state, modoTela: action.payload.modo };

    case "DASHBOARD_LISTADO":
      const dateformat = require("date-format");
      const data = action.payload.data;

      //se não encontrar nada retorna o estado anterior
      if (!data) return state;
      //se encontrar informações do dispositivo monta um objeto formatado
      const registro = {};
      const { dias, horas, minutos } = data.tempo_ultima_mensagem;
      registro.tempo_ultima_mensagem = `${dias} ${
        dias == 1 ? "dia" : "dias"
      }, ${horas} ${horas == 1 ? "hora" : "horas"}, ${minutos} ${
        minutos == 1 ? "minuto" : "minutos"
      }`;

      registro.data_hora = dateformat(
        "dd/MM/yyyy hh:mm",
        new Date(data.data_hora)
      );
      registro.estado_bateria = calcularPercentualBateria(data.bat); //objeto ex: {percentualBateria:50,alerta:''}
      registro.precipitacao = data.precpt;
      registro.clima_atual_temperatura = data.extemp;
      registro.clima_atual_umidade_ar = data.extumid;
      registro.umidade_solo = data.umidsolo;
      registro.velocidade_vento = data.velvent;
      registro.direcao_vento = data.sigla_dirvent;
      registro.temperatura_ar_minima = data.temperatura_ar_minima;
      registro.temperatura_ar_maxima = data.temperatura_ar_maxima;
      registro.umidade_ar_minima = data.umidade_ar_minima;
      registro.umidade_ar_maxima = data.umidade_ar_maxima;
      registro.soma_precipitacao = data.soma_precipitacao;

      registro.intermediario = data.intermediario;
      registro.rssi = data.rssi
        ? data.rssi < -70
          ? "Ruim"
          : data.rssi <= -50
          ? "Bom"
          : "Excelente"
        : "";

      registro.serial = data.serial;

      return {
        ...state,
        registro: { ...state.registro, ...registro },
      };
    case "SET_PREVISAO_TEMPO_DASHBOARD":
      return {
        ...state,
        registro: { ...state.registro, previsao: action.payload.data },
      };
    case "RESETAR_DASHBOARD":
      return INITIAL_STATE;
    default:
      return state;
  }
}
function calcularPercentualBateria(valorBateria) {
  //valor máximo 3.7 e percentual máximo 30%
  if (valorBateria <= 3.7) {
    const percentualAte30 = calcularPercentualEntre2Valores({
      valorBateria,
      valorMaximoAnterior: 0,
      valorMaximoAtual: 3.7,
      diferencaMaximaValor: 3.7,
      diferencaMaximaPorcentagem: 30,
      percentualMaximoAnterior: 0,
    });
    return percentualAte30;
  }

  //valor máximo 3.8 e percentual máximo 40%
  if (valorBateria > 3.7 && valorBateria <= 3.8) {
    const percentualAte40 = calcularPercentualEntre2Valores({
      valorBateria,
      valorMaximoAnterior: 3.7,
      valorMaximoAtual: 3.8,
      diferencaMaximaValor: 0.1,
      diferencaMaximaPorcentagem: 10,
      percentualMaximoAnterior: 30,
    });
    return percentualAte40;
  }
  //valor máximo 3.9 e percentual máximo 65%
  if (valorBateria > 3.8 && valorBateria <= 3.9) {
    const percentualAte65 = calcularPercentualEntre2Valores({
      valorBateria,
      valorMaximoAnterior: 3.8,
      valorMaximoAtual: 3.9,
      diferencaMaximaValor: 0.1,
      diferencaMaximaPorcentagem: 25,
      percentualMaximoAnterior: 40,
    });
    return percentualAte65;
  }
  //valor máximo 4.0 e percentual máximo 75%
  if (valorBateria > 3.9 && valorBateria <= 4) {
    const percentualAte75 = calcularPercentualEntre2Valores({
      valorBateria,
      valorMaximoAnterior: 3.9,
      valorMaximoAtual: 4,
      diferencaMaximaValor: 0.1,
      diferencaMaximaPorcentagem: 10,
      percentualMaximoAnterior: 65,
    });
    return percentualAte75;
  }
  //valor máximo 4.05 e percentual máximo 85%
  if (valorBateria > 4 && valorBateria <= 4.05) {
    const percentualAte85 = calcularPercentualEntre2Valores({
      valorBateria,
      valorMaximoAnterior: 4,
      valorMaximoAtual: 4.05,
      diferencaMaximaValor: 0.05,
      diferencaMaximaPorcentagem: 10,
      percentualMaximoAnterior: 75,
    });
    return percentualAte85;
  }

  //valor máximo 4.1 e percentual máximo 90%
  if (valorBateria > 4.05 && valorBateria <= 4.1) {
    const percentualAte90 = calcularPercentualEntre2Valores({
      valorBateria,
      valorMaximoAnterior: 4.05,
      valorMaximoAtual: 4.1,
      diferencaMaximaValor: 0.05,
      diferencaMaximaPorcentagem: 5,
      percentualMaximoAnterior: 85,
    });
    return percentualAte90;
  }

  //valor máximo 4.15 e percentual máximo 95%
  if (valorBateria > 4.1 && valorBateria <= 4.15) {
    const percentualAte95 = calcularPercentualEntre2Valores({
      valorBateria,
      valorMaximoAnterior: 4.1,
      valorMaximoAtual: 4.15,
      diferencaMaximaValor: 0.05,
      diferencaMaximaPorcentagem: 5,
      percentualMaximoAnterior: 90,
    });
    return percentualAte95;
  }

  //valor máximo 4.2 e percentual máximo 100%
  if (valorBateria > 4.15) {
    const percentualAte100 = calcularPercentualEntre2Valores({
      valorBateria,
      valorMaximoAnterior: 4.15,
      valorMaximoAtual: 4.2,
      diferencaMaximaValor: 0.05,
      diferencaMaximaPorcentagem: 5,
      percentualMaximoAnterior: 95,
    });
    return percentualAte100;
  }

  function calcularPercentualEntre2Valores({
    valorBateria,
    valorMaximoAnterior,
    valorMaximoAtual,
    diferencaMaximaValor,
    diferencaMaximaPorcentagem,
    percentualMaximoAnterior,
  }) {
    const percentualComAlerta = { percentualBateria: 0, alerta: "" };
    if (valorBateria > 4.3) {
      percentualComAlerta.alerta = "(Nível de sobretensão)";
    }

    //controla pra não ultrapassar o maior valor possível (4.2), para não causar erro de cálculo
    valorBateria = valorBateria > 4.2 ? 4.2 : valorBateria;

    if (
      valorBateria > valorMaximoAnterior &&
      valorBateria <= valorMaximoAtual
    ) {
      //faz interpolação para ver a diferença entra os valores e calcular a % referente a essa diferença, depois soma percentual máximo anterior
      const diferencaValor = valorBateria - valorMaximoAnterior;
      percentualComAlerta.percentualBateria =
        Math.round(
          (diferencaValor * diferencaMaximaPorcentagem) / diferencaMaximaValor
        ) + percentualMaximoAnterior;
    }

    return percentualComAlerta;
  }

  return { percentualBateria: 0, alerta: "" };
}
